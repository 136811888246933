<template>
  <v-container
    id="social"
    tag="section"
  >
    <v-row>
     
     
      <v-col
        cols="12"
        md="3"
      >
        <newest-videos />
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <NewestPosts />
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <newest-articles />
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <tags />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'HomeLatest',

    components: {
      NewestArticles: () => import('@/components/NewestArticles'),
      NewestVideos: () => import('@/components/NewestVideos'),
      NewestPosts: () => import('@/components/NewestPosts'),
      Tags: () => import('@/components/Tags'),
    },
  }
</script>
